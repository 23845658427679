import { format } from 'date-fns';
import React, { useMemo } from 'react';

import { t } from '@/utility/localization';

import { formatCompactCount } from '@/features/community/utils';

import PostActionButton from './PostActionButton';

interface PostActionBtnsRowProps {
  commentCount: number;
  hideLikeCount: boolean;
  hideViewCount: boolean;
  impressionCount: number;
  isCommunityAdmin: boolean;
  isPostLiked: boolean;
  likeCount: number;
  onCommentClick: () => void;
  onLikeClick: () => void;
  postCreationDateString: string;
  stretchButtons: boolean;
  hideImpressionCount?: boolean;
}

const impressionsFeatureLaunchDate = new Date('2024-07-22');

const PostActionBtnsRow: React.FC<PostActionBtnsRowProps> = ({
  postCreationDateString,
  isPostLiked,
  onLikeClick,
  onCommentClick,
  likeCount,
  commentCount,
  impressionCount,
  stretchButtons,
  isCommunityAdmin,
  hideLikeCount,
  hideViewCount,
  hideImpressionCount = false
}) => {
  const formattedLikeCount =
    hideLikeCount || likeCount < 1 ? '' : formatCompactCount(likeCount);

  const postImpressions = useMemo(() => {
    const postCreationDate = new Date(postCreationDateString);

    const isPostCreatedAfterImpressionsFeatureLaunch =
      postCreationDate > impressionsFeatureLaunchDate;

    if (isPostCreatedAfterImpressionsFeatureLaunch) {
      return {
        toolTipText: null,
        count:
          impressionCount > 0 ? formatCompactCount(impressionCount) : 1
      };
    } else {
      return {
        toolTipText: t('views-for-posts-before-date-is-not-available', {
          date: format(impressionsFeatureLaunchDate, 'dd MMM yyyy')
        }),
        count: null
      };
    }
  }, [impressionCount, postCreationDateString]);

  // hide if envVariable = true or user is not a admin + no count
  const hideImpressionsButton =
    hideViewCount || (!isCommunityAdmin && !postImpressions.count);

  return (
    <div className="flex items-center gap-2 w-full">
      {!hideImpressionsButton && (
        <PostActionButton
          icon="eye"
          onClick={null}
          title={hideImpressionCount ? '' : postImpressions.count}
          stretch={stretchButtons}
          iconColor="#1B1B18A6"
          tooltipText={postImpressions.toolTipText}
        />
      )}

      {isPostLiked && (
        <PostActionButton
          icon="heart-filled"
          iconColor={'#E5484D'}
          onClick={onLikeClick}
          title={formattedLikeCount}
          stretch={stretchButtons}
        />
      )}
      {!isPostLiked && (
        <PostActionButton
          icon={'heart'}
          iconColor={'#1B1B18A6'}
          onClick={onLikeClick}
          title={formattedLikeCount}
          stretch={stretchButtons}
        />
      )}

      <PostActionButton
        icon="message-text-square-02"
        onClick={onCommentClick}
        title={commentCount > 0 ? commentCount : ''}
        stretch={stretchButtons}
        iconColor="#1B1B18A6"
      />
    </div>
  );
};

export default PostActionBtnsRow;
